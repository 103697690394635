<template>
	<div class="grid dashboard">

		<div class="col-12">
			<div class="card">
				<div class="grid">
					<div class="col-4" id="tarihfiltresi">
						<div class="field p-fluid">
							<label for="servisTalepTarihi">Tarih Filtresi</label>
							<Calendar showButtonBar id="servisTalepTarihi" v-model="dates" selectionMode="range" :manualInput="false" :showIcon="true" @date-select="onDateSelect" />
						</div>
					</div>
					<div class="col-6" id="tarih" style="visibility: hidden;">
						<div class="field p-fluid">
							<h4>
								Seçili Tarih <br>
								{{ formatDate(dates[0]) }} - {{ formatDate(dates[1]) }}
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="col-12 md:col-4 lg:col-2">
			<div class="card green-bgcolor solid-surface-text-color p-3">
				<div class="flex align-items-center">
					<h6>Açık Talep</h6>
				</div>
				<div class="flex justify-content-between mt-1 flex-wrap">
					<div class="flex flex-column" style="width: 150px;">
						<span class="mb-1 fs-xlarge">{{ chartOption_Ozet.AcikTalepBuAy }} adet</span>
						<span class="overview-status p-1 fs-normal">{{ chartOption_Ozet.AcikTalepBuYil }} (Bu yıl)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-4 lg:col-2">
			<div class="card green-bgcolor solid-surface-text-color p-3">
				<div class="flex align-items-center">
					<h6>Kapalı Talep</h6>
				</div>
				<div class="flex justify-content-between mt-1 flex-wrap">
					<div class="flex flex-column" style="width: 150px;">
						<span class="mb-1 fs-xlarge">{{ chartOption_Ozet.KapaliTalepBuAy }} adet</span>
						<span class="overview-status p-1 fs-normal">{{ chartOption_Ozet.KapaliTalepBuYil }} (Bu yıl)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-4 lg:col-2">
			<div class="card pink-bgcolor solid-surface-text-color p-3">
				<div class="flex align-items-center">
					<h6>Ücretli / Ücretsiz</h6>
				</div>
				<div class="flex justify-content-between mt-1 flex-wrap">
					<div class="flex flex-column" style="width: 150px;">
						<span class="mb-1 fs-xlarge">{{ chartOption_Ozet.UcretliBuAy }} / {{ chartOption_Ozet.UcretsizBuAy }}</span>
						<span class="overview-status p-1 fs-normal">{{ chartOption_Ozet.UcretliBuAy }} / {{ chartOption_Ozet.UcretsizBuYil }} (Bu yıl)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-4 lg:col-2">
			<div class="card pink-bgcolor solid-surface-text-color p-3">
				<div class="flex align-items-center">
					<h6>Ücretli Servis</h6>
				</div>
				<div class="flex justify-content-between mt-1 flex-wrap">
					<div class="flex flex-column" style="width: 150px;">
						<span class="mb-1 fs-xlarge">{{ formatNumber(chartOption_Ozet.UcretliServisBuAy) }} €</span>
						<span class="overview-status p-1 fs-normal">{{ formatNumber(chartOption_Ozet.UcretliServisBuYil) }} € (Bu yıl)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-4 lg:col-2">
			<div class="card orange-bgcolor solid-surface-text-color p-3">
				<div class="flex align-items-center">
					<h6>Normal Süre</h6>
				</div>
				<div class="flex justify-content-between mt-1 flex-wrap">
					<div class="flex flex-column" style="width: 150px;">
						<span class="mb-1 fs-xlarge">{{ formatNumber(chartOption_Ozet.NormalSureBuAy) }} saat</span>
						<span class="overview-status p-1 fs-normal">{{ formatNumber(chartOption_Ozet.NormalSureBuYil) }} saat (Bu yıl)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-4 lg:col-2">
			<div class="card orange-bgcolor solid-surface-text-color p-3">
				<div class="flex align-items-center">
					<h6>Fazla Mesai</h6>
				</div>
				<div class="flex justify-content-between mt-1 flex-wrap">
					<div class="flex flex-column" style="width: 150px;">
						<span class="mb-1 fs-xlarge">{{ formatNumber(chartOption_Ozet.FazlaMesaiBuAy) }} saat</span>
						<span class="overview-status p-1 fs-normal">{{ formatNumber(chartOption_Ozet.FazlaMesaiBuYil) }} saat (Bu yıl)</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-4 lg:col-4">
			<div class="card height-100">
				<div class="card-header">
					<h5>Konumlara Göre Servis Talebi Adetleri</h5>
				</div>
				<vuecharts :option="chartOption_KonumlaraGoreServisTalepleri" style="height: 500px" ref="chart" />
			</div>
		</div>

		<div class="col-4 lg:col-4">
			<div class="card height-100">
				<div class="card-header">
					<h5>Markalara Göre Servis Talebi Adetleri</h5>
				</div>
				<vuecharts :option="chartOption_MarkalaraGoreServisTalepleri" style="height: 500px" ref="chart" />
			</div>
		</div>

		<div class="col-4 lg:col-4">
			<div class="card height-100">
				<div class="card-header">
					<h5>Türlerine Göre Servis Raporları</h5>
				</div>
				<vuecharts :option="chartOption_TurlerineGoreServisRaporlari" style="height: 500px" ref="chart" />
			</div>
		</div>

		<div class="col-12 lg:col-12">
			<div class="card height-100">
				<div class="card-header">
					<h5>Konum ve Markalara Göre Servis Talebi Adetleri</h5>
				</div>
				<vuecharts :option="chartOption_KonumlaraVeMarkalaraGoreServisTalepleri" style="height: 500px" ref="chart2" />
			</div>
		</div>

		<div class="col-12 lg:col-12">
			<div class="card height-100">
				<div class="card-header">
					<h5>Konum ve Türlere Göre Servis Rapor Adetleri</h5>
				</div>
				<vuecharts :option="chartOption_KonumlaraVeTurlereGoreServisRaporlari" style="height: 500px" ref="chart2" />
			</div>
		</div>

	</div>
	
</template>
	
<script>
	import CrmService from "../service/CrmService";
	import user from '../store/user';

	export default {
		data() {
			return {
				dates: [],

				crmService: null,
				chartOption_KonumlaraGoreServisTalepleri: {},
				chartOption_MarkalaraGoreServisTalepleri: {},
				chartOption_TurlerineGoreServisRaporlari: {},
				chartOption_MarkalaraVeMarkalaraGoreServisTalepleri: {},
				chartOption_KonumlaraVeTurlereGoreServisRaporlari: {},
				chartOption_Ozet: {},
			}
		},
		async created() {
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Servis Panosu');
				if (yetkisiVarmi == false) {
					this.$router.replace({ name: 'accessdenied' });
				}
			}
			
			this.crmService = new CrmService();

			console.log('created');

			const currentDate = new Date();
			const currentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			this.dates.push(currentMonthDate);
			this.dates.push(new Date());
			
			this.OnLoad();
		},
		computed: {
			profileData(){
				return this.$store.getters.getProfile;
			}
		},
		methods: {
			async OnLoad(){
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});
				
				try {
					debugger;

					let data = await this.crmService.getKonumlaraGoreServisTalepleri(this.dates);
					if (data.chartJson.length > 0) {
						this.chartOption_KonumlaraGoreServisTalepleri = JSON.parse(data.chartJson);
					}

					let data2 = await this.crmService.getKonumlaraVeMarkalaraGoreServisTalepleri(this.dates);
					if (data2.chartJson.length > 0) {
						this.chartOption_KonumlaraVeMarkalaraGoreServisTalepleri = JSON.parse(data2.chartJson);
					}

					let data3 = await this.crmService.getMarkalaraGoreServisTalepleri(this.dates);
					if (data3.chartJson.length > 0) {
						this.chartOption_MarkalaraGoreServisTalepleri = JSON.parse(data3.chartJson);
					}

					let data4 = await this.crmService.getTurlerineGoreServisRaporlari(this.dates);
					if (data4.chartJson.length > 0) {
						this.chartOption_TurlerineGoreServisRaporlari = JSON.parse(data4.chartJson);
					}

					let data5 = await this.crmService.getKonumlaraVeTurlereGoreServisRaporlari(this.dates);
					if (data5.chartJson.length > 0) {
						this.chartOption_KonumlaraVeTurlereGoreServisRaporlari = JSON.parse(data5.chartJson);
					}

					let dataOzet = await this.crmService.getOzetBilgi(this.dates);
					debugger;
					if (dataOzet.jsonData.length > 0) {
						debugger;
						this.chartOption_Ozet = dataOzet.jsonData[0];
					}

				} catch (error) {
					console.log(error);
				}
				finally {
					loader.hide();
				}
			},
			onDateSelect(){
				this.OnLoad();
			},
			formatDate(date){
				if (date) {
					return date.getDate() + '.' + (parseInt(date.getMonth()) + 1) + '.' + date.getFullYear();
				}
				else {
					return "";
				}
			},
			formatNumber(value) {
				if (value != undefined) {
					return value.toLocaleString('tr-TR');
				}
				else {
					return 0;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	
</style>	